import { Grid2 } from '@mui/material';
import { useMultipleEffectiveDateTime } from '@/components/_dashboard/promotion/effectiveDateTime';
import { EffectiveDateTimeType } from '@/components/_dashboard/promotion/effectiveDateTime/type/type';
import { CommonInput } from '@/components/_dashboard/promotion/formik/commonInput';
import {
    checkEffectiveDateTimeListValid,
    checkOfferVoucherEffectiveDateTimeValid,
    getOfferVoucherConditionValidationList
} from '@/components/_dashboard/promotion/tools';
import React, { useCallback, useEffect } from 'react';
import { IPromoProps } from '../../type';
import { renderContent, tabContent } from '../tabContent';
import { getErrorMessage } from '@/utils/stringHelper';

export const OfferVoucherConditions: React.FC<IPromoProps> = (props) => {
    const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
    const { values } = formik;
    const { isPercentage } = values;
    const time = formik.values.offerVoucherSettings?.effectiveDateTime || [];
    const setEffectiveDateTime = (newList: EffectiveDateTimeType[]) => {
        formik.setFieldValue('offerVoucherSettings.effectiveDateTime', newList);
    };
    const { effectiveDateTime } = useMultipleEffectiveDateTime({
        dateTimesList: time,
        setDateTimeList: setEffectiveDateTime,
        isOffer: false
    });

    const canMove = useCallback(async () => {
        let message = '';
        let canMove = true;
        try {
            const list = getOfferVoucherConditionValidationList();
            for (const path of list) {
                await setFieldTouchedNameFunc(path);
            }
            if (!values.offerVoucherSettings) {
                throw new Error('Offer Voucher Settings is required');
            }
            checkEffectiveDateTimeListValid(values.offerVoucherSettings?.effectiveDateTime);
            checkOfferVoucherEffectiveDateTimeValid(values.effectiveDateTime, values.offerVoucherSettings?.effectiveDateTime);
        } catch (e) {
            message = getErrorMessage(e);
            canMove = false;
        }
        return {
            message: message,
            canMove: canMove
        };
    }, [values, setFieldTouchedNameFunc]);

    const wrappedCanMove = useCallback(() => canMove(), [canMove]);
    useEffect(() => {
        setCanMoveFunc(() => wrappedCanMove);
    }, [setCanMoveFunc, wrappedCanMove]);

    const commonContents: tabContent[] = [
        {
            node: (
                <CommonInput
                    formik={formik}
                    fieldPath={'offerVoucherSettings.minimumAmount'}
                    type="number"
                    label="Minimum Order Amount"
                    TooltipText="The minimum amount your order must reach to qualify for this offer."
                    placeholder="Minimum Amount?"
                />
            )
        },
        {
            node: (
                <CommonInput
                    formik={formik}
                    fieldPath={'offerVoucherSettings.upToAmount'}
                    type={'number'}
                    label="Up To Amount"
                    TooltipText="The maximum amount that can be discounted by this offer."
                    placeholder="Up To Amount?"
                />
            ),
            // only show if isPercentage
            isHidden: !isPercentage
        },
        {
            mdSize: 12,
            node: (
                <CommonInput
                    formik={formik}
                    fieldPath={'offerVoucherSettings.voucherTerms'}
                    label="Voucher Terms"
                    TooltipText="The terms and conditions that apply to the use of this voucher."
                    placeholder="Detail the conditions under which the voucher and offer applies"
                    multiline={true}
                />
            )
        },
        {
            mdSize: 12,
            node: effectiveDateTime
        }
    ];

    return (
        <Grid2 sx={{ width: '100%' }} container spacing={1}>
            {renderContent(commonContents)}
        </Grid2>
    );
};
