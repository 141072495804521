import { FormikProps } from 'formik';
import { Promotion } from '@/@type/promotion';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useEffect } from 'react';
import { CommonInput } from '../formik/commonInput';

interface PromoFreeNProps {
    formik: FormikProps<Promotion>;
    label?: string;
    sx?: CSSProperties;
}
export const PromoFreeN: React.FC<PromoFreeNProps> = (props: PromoFreeNProps) => {
    const { formik, label } = props;
    const { setFieldValue } = formik;
    const { freeN } = formik.values;
    const { t } = useTranslation();

    useEffect(() => {
        if (Number(freeN) === 0) {
            setFieldValue('freeN', 'Infinite');
        }
    }, [freeN, setFieldValue]);

    return (
        <>
            <CommonInput
                formik={formik}
                fieldPath="freeN"
                type="text"
                disabled={freeN === 'Infinite'}
                placeholder={t('Get Qty')}
                label={label || t('Get how many?')}
            />
        </>
    );
};
