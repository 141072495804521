import { PaletteOptions } from '@mui/material';
import { GREY } from './palette';

declare module '@mui/material/styles' {
    interface Theme {
        bookingCalendar: BookingCalendar;
    }
    interface ThemeOptions {
        bookingCalendar: BookingCalendar;
    }
}

export interface BookingCalendar {
    weekTitles: CalendarWeekTitles;
    dayBlocks: Partial<PaletteOptions>;
}

export interface CalendarWeekTitles {
    weekdays: Partial<PaletteOptions>;
    weekends: Partial<PaletteOptions>;
}

export const MY_BOOKING_CALENDAR_LIGHT: BookingCalendar = {
    weekTitles: {
        weekdays: {
            background: {
                default: GREY[200]
            }
        },
        weekends: {
            background: {
                default: '#FFC0CB'
            }
        }
    },
    dayBlocks: {
        background: {
            default: GREY[100]
        }
    }
};

export const MY_BOOKING_CALENDAR_DARK: BookingCalendar = {
    weekTitles: {
        weekdays: {
            background: {
                default: GREY[800]
            }
        },
        weekends: {
            background: {
                default: '#4A2A2E'
            }
        }
    },
    dayBlocks: {
        background: {
            default: GREY[800]
        }
    }
};



export const bookingCalendarTheme = {
    light: MY_BOOKING_CALENDAR_LIGHT,
    dark: MY_BOOKING_CALENDAR_DARK
};
