import { useMemo, ReactNode } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { bookingCalendarTheme } from './bookingCalendar';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
    children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {
    const { themeMode, themeDirection } = useSettings();
    const isLight = themeMode === 'light';

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
            shape,
            typography,
            breakpoints,
            direction: themeDirection,
            shadows: isLight ? shadows.light : shadows.dark,
            customShadows: isLight ? customShadows.light : customShadows.dark,
            bookingCalendar: isLight ? bookingCalendarTheme.light : bookingCalendarTheme.dark
        }),
        [isLight, themeDirection]
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
