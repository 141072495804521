import { FormikProps } from 'formik';
import { Checkbox, FormControl, FormControlLabel, SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeepKeyOf } from './type';
import { Label } from '../styles';

interface commonCheckBoxProps<T> {
    formik: FormikProps<T>;
    value?: boolean;
    fieldPath?: DeepKeyOf<T>;
    disabled?: boolean;
    handleOnChange?: (event: React.SyntheticEvent, checked: boolean) => void;
    sx?: SxProps<Theme>;
    title?: string;
    label?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const CommonCheckBox = <T,>(props: commonCheckBoxProps<T>) => {
    const { formik, value, fieldPath = '', handleOnChange, disabled = false, sx = {}, title, label, labelPlacement = 'end' } = props;
    const { getFieldProps } = formik;

    const { t } = useTranslation();
    const checkBoxStyle: SxProps<Theme> = {
        ...sx
    };

    const getValue = () => {
        if (value !== undefined) {
            return value;
        }
        const fieldValue = getFieldProps(fieldPath).value;
        if (fieldValue === undefined) {
            return false;
        }
        return fieldValue;
    };
    const handleValueOnChange = (event: React.SyntheticEvent, checked: boolean) => {
        if (handleOnChange) {
            handleOnChange(event, checked);
            return;
        }
        formik.setFieldValue(fieldPath, checked);
    };
    return (
        <>
            <FormControl>
                {title && <Label>{t(title)}</Label>}
                <FormControlLabel
                    value={getValue()}
                    checked={getValue()}
                    onChange={handleValueOnChange}
                    control={<Checkbox sx={checkBoxStyle} />}
                    label={t(label || '')}
                    disabled={disabled}
                    labelPlacement={labelPlacement}
                />
            </FormControl>
        </>
    );
};
