import { Method } from 'axios';

interface BookingAPIEndpoint {
    url: string;
    method: Method;
}

const BookingAPIBasePath = '/api';

const merchantAreasPath = {
    query: (merchantId: string): BookingAPIEndpoint => ({
        url: `${BookingAPIBasePath}/merchants/${merchantId}/merchantAreas`,
        method: 'GET'
    }),
    update: (merchantId: string): BookingAPIEndpoint => ({
        url: `${BookingAPIBasePath}/merchants/${merchantId}/merchantAreas`,
        method: 'PUT'
    })
};

export const bookingAPIPath = {
    merchant: {
        query: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/merchants/${merchantId}`,
            method: 'GET'
        }),
        areas: merchantAreasPath
    },
    booking: {
        create: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}`,
            method: 'POST'
        }),
        query: (merchantId: string, yearStr: string, bookingNumber: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/${yearStr}/${bookingNumber}`,
            method: 'GET'
        }),
        update: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}`,
            method: 'PUT'
        }),
        updateBookingSettings: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/settings`,
            method: 'PUT'
        }),
        queryBookingSettings: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/settings`,
            method: 'GET'
        }),
        queryBookingList: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}`,
            method: 'GET'
        }),
        queryBookingAvailableTime: (merchantId: string): BookingAPIEndpoint => ({
            url: `${BookingAPIBasePath}/booking/${merchantId}/availableBookingTime`,
            method: 'GET'
        })
    }
};
