import { Card, Grid2 } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
// routes
// @types
import { Promotion } from '../../../../@type/promotion';

import { PromotionType } from '@/__generated__/types';
import LoadingComponent from '@/components/LoadingComponent';
import { useSnackbarHelper } from '@/components/useSnackbarHelper';
import { RootState, useSelector } from '@/redux/store';
import { PATH_DASHBOARD } from '@/routes/paths';
import { getErrorMessage } from '@/utils/stringHelper';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DeepKeyOf } from '../formik/type';
import { promoValidationSchema } from '../promoValidationSchema';
import { createPromoFunc, getInitialPromotionState, initOfferVoucherSettings, queryPromoFunc, updatePromoFunc } from '../tools';
import { usePromoStepper } from './stepper/stepper';
import { StepRenderer } from './tabs/stepRenderer';
import { PromotionProps } from './type';

export const CreateOfferForm = (props: PromotionProps) => {
    const { showSnackbar } = useSnackbarHelper();
    const navigate = useNavigate();
    const params = useParams();
    const user = useSelector((state: RootState) => state.auth.user);
    const { isLoyalty = false } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isEdit = params.name ? true : false;
    const [promoType] = useState<PromotionType>(PromotionType.BuynGetFree);
    const initPromo: Promotion = {
        ...getInitialPromotionState(promoType, true, isLoyalty, user?.merchantId),
        offerVoucherSettings: initOfferVoucherSettings()
    };
    const [promotion] = useState<Promotion>(initPromo);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: promotion,
        validationSchema: promoValidationSchema,
        onSubmit: async () => {}
    });

    const { setFieldTouched, setValues } = formik;
    const { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent } = usePromoStepper({
        isSubmitting,
        action: CreateOrUpdatePromotion(isEdit),
        promotion: formik.values
    });

    const setFieldTouchedName = useCallback(
        async (name: DeepKeyOf<Promotion>) => {
            if (!name) return;
            const formikErrors = await setFieldTouched(name, true, true);
            if (!formikErrors) return;
            const error = get(formikErrors, name) as string | undefined;
            if (!error) return;
            throw new Error(error);
        },
        [setFieldTouched]
    );

    const fetchData = useCallback(async () => {
        if (!params.name) return;
        try {
            setIsLoading(true);
            const promotion = await queryPromoFunc(promoType, params.name);
            if (!promotion) return;
            setValues(promotion);
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            console.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }, [params.name, promoType, setValues]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    async function createPromotion(isPublished: boolean) {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            const isSuccess = await createPromoFunc(formik.values, promoType, isPublished);
            if (isSuccess) {
                showSnackbar('Offer created successfully', 'success');
                navigate(PATH_DASHBOARD.promotion.list);
            }
        } catch (error) {
            showSnackbar('Failed to create offer', 'error');
            const errorMessage = getErrorMessage(error);
            console.error(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    }

    async function updatePromotion(isPublished: boolean) {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            const isSuccess = await updatePromoFunc(formik.values, promoType, isPublished);
            if (isSuccess) {
                showSnackbar('Offer update successfully', 'success');
                setIsSubmitting(false);
                navigate(PATH_DASHBOARD.promotion.list);
            }
        } catch (error) {
            showSnackbar('Failed to update offer', 'error');
            const errorMessage = getErrorMessage(error);
            console.error(errorMessage);
            setIsSubmitting(false);
        }
    }

    function CreateOrUpdatePromotion(isEdit: boolean) {
        if (isEdit) {
            return updatePromotion;
        } else {
            return createPromotion;
        }
    }
    const commonComponentProps = {
        formik,
        setCanMoveFunc,
        setFieldTouchedNameFunc: setFieldTouchedName
    };

    if (isLoading && isEdit) {
        return <LoadingComponent />;
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off">
                <Grid2 container spacing={3} justifyContent="center">
                    <Grid2 size={{ xs: 12, sm: 10, md: 10 }}>
                        <Card sx={{ p: 3 }}>
                            <BreadcrumbsComponent />
                            <StepRenderer currentStep={currentStep} commonComponentProps={commonComponentProps} formik={formik} />
                            <MoveToStepComponent />
                        </Card>
                    </Grid2>
                </Grid2>
            </Form>
        </FormikProvider>
    );
};
