// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import { useTranslation } from 'react-i18next';
import { UserRole } from '@/__generated__/types';

// ----------------------------------------------------------------------

export interface SidebarSection {
    title: string;
    path?: string;
    icon?: JSX.Element;
    includeRoles?: UserRole[];
    excludeRoles?: UserRole[];
    items?: SidebarSection[];
}

const getIcon = (name: string) => <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    booking: getIcon('ic_booking'),
    promotion: getIcon('ic_promotion'),
    loyalty: getIcon('ic_loyalty'),
    merchant: getIcon('ic_merchant'),
    menu: getIcon('ic_menu'),
    order: getIcon('ic_order'),
    transactions: getIcon('ic_transaction'),
    reports: getIcon('ic_reports'),
    settings: getIcon('ic_settings'),
    giftCard: getIcon('card_giftcard'),
    question: getIcon('ic_question')
};

const useSidebarConfig = (userRole: any) => {
    const { t } = useTranslation();
    // const merchant = useSelector((state: RootState) => state.auth?.merchant) || null;

    // header
    const header: SidebarSection = {
        title: t('home'),
        includeRoles: [],
        items: [
            {
                title: t('Dashboard'),
                path: PATH_DASHBOARD.general.generalApp,
                icon: ICONS.dashboard
            }
        ]
    };

    // body
    const userPart: SidebarSection = {
        title: t('User'),
        path: PATH_DASHBOARD.user.root,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin],
        icon: ICONS.user,
        items: [
            { title: t('List'), path: PATH_DASHBOARD.user.list },
            { title: t('Create'), path: PATH_DASHBOARD.user.newUser, icon: ICONS.mail }
        ]
    };

    const merchantsPart: SidebarSection = {
        title: t('Merchants'),
        path: PATH_DASHBOARD.merchant.root,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin],
        icon: ICONS.merchant,
        items: [
            { title: t('List'), path: PATH_DASHBOARD.merchant.list },
            { title: t('Create'), path: PATH_DASHBOARD.merchant.newMerchant }
        ]
    };

    const myMerchantPart: SidebarSection = {
        title: t('My Merchant'),
        path: PATH_DASHBOARD.merchant.root,
        icon: ICONS.merchant,
        includeRoles: [
            UserRole.Admin,
            UserRole.MarketingAdmin,
            UserRole.MarketingAdmin,
            UserRole.MerchantManager,
            UserRole.MerchantGroupAdmin
        ],
        items: [
            { title: t('Update Merchant'), path: PATH_DASHBOARD.merchant.updateMerchant },
            { title: t('Update Opening Hours'), path: PATH_DASHBOARD.merchant.updateOpeningHours },
            { title: t('Surcharge'), path: PATH_DASHBOARD.merchant.merchantSurcharges }
        ]
    };

    const menuPart: SidebarSection = {
        title: t('Menu'),
        path: PATH_DASHBOARD.menu.root,
        icon: ICONS.menu,
        includeRoles: [
            UserRole.Admin,
            UserRole.MarketingAdmin,
            UserRole.MarketingAdmin,
            UserRole.MerchantManager,
            UserRole.MerchantGroupAdmin,
            UserRole.MenuAdmin
        ],
        items: [
            { title: t('List'), path: PATH_DASHBOARD.menu.list },
            { title: t('Create'), path: PATH_DASHBOARD.menu.newMenu }
        ]
    };

    const appSectionsPart: SidebarSection = {
        title: t('App Sections'),
        path: PATH_DASHBOARD.appSections.root,
        icon: ICONS.transactions,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin],
        items: [{ title: t('List'), path: PATH_DASHBOARD.appSections.list }]
    };

    const popularRestaurantCategoriesPart: SidebarSection = {
        title: t('Popular Restaurant Categories'),
        path: PATH_DASHBOARD.popularRestaurantCategories.root,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin],
        icon: ICONS.transactions,
        items: [{ title: t('List'), path: PATH_DASHBOARD.popularRestaurantCategories.list }]
    };

    const promotionPart: SidebarSection = {
        title: t('Promotion'),
        path: PATH_DASHBOARD.promotion.root,
        icon: ICONS.transactions,
        includeRoles: [
            UserRole.Admin,
            UserRole.MarketingAdmin,
            UserRole.MarketingAdmin,
            UserRole.MerchantManager,
            UserRole.MerchantGroupAdmin
        ],
        items: [
            { title: t('List'), path: PATH_DASHBOARD.promotion.list },
            { title: t('Create'), path: PATH_DASHBOARD.promotion.newPromotion },
            { title: t('History'), path: PATH_DASHBOARD.promotion.history }
        ]
    };

    const loyaltyPart: SidebarSection = {
        title: t('Loyalty'),
        path: PATH_DASHBOARD.loyalty.root,
        icon: ICONS.loyalty,
        includeRoles: [
            UserRole.Admin,
            UserRole.MarketingAdmin,
            UserRole.MarketingAdmin,
            UserRole.MerchantManager,
            UserRole.MerchantGroupAdmin
        ],
        items: [{ title: t('Members'), path: PATH_DASHBOARD.loyalty.member }]
    };

    const giftCardPart: SidebarSection = {
        title: t('Gift Card'),
        path: PATH_DASHBOARD.giftCard.root,
        icon: ICONS.giftCard,
        includeRoles: [UserRole.Admin, UserRole.GiftCardAdmin],
        items: [
            {
                title: t('Accounts'),
                includeRoles: [UserRole.Admin],
                path: PATH_DASHBOARD.giftCard.accounts
            },
            { title: t('Balance'), path: PATH_DASHBOARD.giftCard.balance },
            { title: t('History'), path: PATH_DASHBOARD.giftCard.history }
        ]
    };

    const FAQPart: SidebarSection = {
        title: t('FAQ'),
        path: PATH_DASHBOARD.FAQ.root,
        icon: ICONS.question,
        includeRoles: [UserRole.Admin],
        items: [
            {
                title: t('Sections'),
                includeRoles: [UserRole.Admin],
                path: PATH_DASHBOARD.FAQ.sections
            },
            {
                title: t('Preview'),
                includeRoles: [UserRole.Admin],
                path: PATH_DASHBOARD.FAQ.preview
            }
        ]
    };

    const orderPart: SidebarSection = {
        title: t('Order'),
        path: PATH_DASHBOARD.order.root,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin, UserRole.MerchantManager, UserRole.MerchantGroupAdmin],
        icon: ICONS.order,
        items: [{ title: t('List'), path: PATH_DASHBOARD.order.list }]
    };

    const bookingSessionsPart: SidebarSection = {
        title: t('Booking Sessions'),
        path: PATH_DASHBOARD.bookingSessions.root,
        icon: ICONS.booking,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin, UserRole.MerchantManager, UserRole.MerchantGroupAdmin],
        items: [
            { title: t('List'), path: PATH_DASHBOARD.bookingSessions.list },
            { title: t('Create'), path: PATH_DASHBOARD.bookingSessions.newBooking }
        ]
    };

    const bookingPart: SidebarSection = {
        title: t('Booking'),
        path: PATH_DASHBOARD.booking.root,
        icon: ICONS.booking,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin, UserRole.MerchantManager, UserRole.MerchantGroupAdmin],
        items: [
            { title: t('Settings'), path: PATH_DASHBOARD.booking.settings },
            { title: t('List'), path: PATH_DASHBOARD.booking.list() },
            { title: t('View'), path: PATH_DASHBOARD.booking.view() },
            { title: t('Calendar'), path: PATH_DASHBOARD.booking.calendar },
            { title: t('Tables'), path: PATH_DASHBOARD.booking.tables },
            { title: t('Link'), path: PATH_DASHBOARD.booking.link }
        ]
    };

    const transactionPart: SidebarSection = {
        title: t('Transactions'),
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.transactions,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin, UserRole.MerchantGroupAdmin],
        items: []
    };

    const reportPart: SidebarSection = {
        title: t('Reports'),
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.reports,
        includeRoles: [UserRole.Admin, UserRole.MarketingAdmin, UserRole.MerchantGroupAdmin],
        items: []
    };

    const body: SidebarSection = {
        title: t('Management'),
        items: [
            userPart,
            merchantsPart,
            myMerchantPart,
            menuPart,
            appSectionsPart,
            popularRestaurantCategoriesPart,
            promotionPart,
            loyaltyPart,
            giftCardPart,
            FAQPart,
            orderPart,
            bookingSessionsPart,
            bookingPart,
            transactionPart,
            reportPart
        ]
    };

    // foot
    const foot: SidebarSection = {
        title: t('App'),
        includeRoles: [],
        items: [
            {
                title: t('Settings'),
                path: PATH_DASHBOARD.user.account,
                icon: ICONS.settings
            }
        ]
    };
    // create a empty SidebarConfig
    const sidebarConfig: SidebarSection[] = [];
    // add the header, body and foot to the sidebarConfig
    sidebarConfig.push(header);
    sidebarConfig.push(body);
    sidebarConfig.push(foot);

    const checkUserRole = (section: SidebarSection): boolean => {
        // check includeRoles and excludeRoles
        let result = true;
        if (section.includeRoles && section.includeRoles.length > 0) {
            result = section.includeRoles.includes(userRole);
        }
        if (section.excludeRoles && section.excludeRoles.length > 0) {
            result = !section.excludeRoles.includes(userRole);
        }
        return result;
    };

    const checkSidebarSectionList = (sectionList: SidebarSection[]) => {
        const newSectionList: SidebarSection[] = [];
        sectionList.forEach((section) => {
            if (checkUserRole(section)) {
                if (section.items) {
                    const newItems = checkSidebarSectionList(section.items);
                    if (newItems.length > 0) {
                        newSectionList.push({ ...section, items: newItems });
                    }
                } else {
                    newSectionList.push(section);
                }
            }
        });
        return newSectionList;
    };

    return checkSidebarSectionList(sidebarConfig);
};
export default useSidebarConfig;
